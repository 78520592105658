import { defineStore } from 'pinia'
import { Flip } from 'number-flip'
import { nextTick, PropType } from 'vue'
import { createBiggerModel, createChestModel, createCoinModel, createPuzzleBG2Model, createPuzzleBGModel, createPuzzleGlossModel, createPuzzleModel, createPuzzlePieceModel, createRadarModel, createStarModel } from '@/common/sequence_frame'
import { globalStore } from '../store'
import random from 'lodash/random'
import { audio } from '@/common/audio'
import { game_v1_map_info, game_v1_puzzle_composite } from '@/common/api'
//  物品信息
export const Goods = defineStore('Goods', {
	//persist: true,
	state: () => ({
		radar: {
			id: 1,
			name: 'radar',
			cd: 10,
			duration: 2,
			desc: '雷达',
			useWhenGetting: false,
			ableimg: '',
			disableimg: '',
			instance: {} as anyObject,
			canuse: true,
		},
		magnifier: {
			id: 1,
			name: 'radar',
			cd: 10,
			duration: 2,
			desc: '放大镜',
			useWhenGetting: false,
			ableimg: '',
			disableimg: '',
			instance: {} as anyObject,
			canuse: true,
		},
		chest: {
			id: 1,
			name: 'chest',
			cd: 10,
			duration: 2,
			desc: '宝箱',
			useWhenGetting: true,
			ableimg: '',
			disableimg: '',
			instance: {} as anyObject,
			canuse: true,
		},
		puzzle: {
			id: 1,
			name: 'puzzle',
			cd: 10,
			duration: 2,
			desc: '拼图',
			useWhenGetting: false,
			ableimg: '',
			disableimg: '',
			instance: {} as anyObject,
			canuse: true,
		},
	}),
	actions: {
		init() {
			console.log('正在物品初始化...')
			console.log('正在物品初始化...完毕!')
		},
		recovery() {
			console.log('正在恢复物品数值...')
			console.log('正在恢复物品数值...完毕!')
		},
		createRadar(mouseEvent: vector2) {
			const { Skill } = globalStore
			audio.surprise.play()
			console.log(`获得雷达1个...`)
			createChestModel({ x: mouseEvent.x, y: mouseEvent.y }, (ani) => {
				console.log(1)
			})
			setTimeout(() => {
				if (this.chest.useWhenGetting) Skill.useRandomGetGoodsEffect()
				//  创建星星模型
				createStarModel({ x: mouseEvent.x, y: mouseEvent.y })
				setTimeout(() => {
					//  创建星星模型
					createPuzzleBGModel({ x: mouseEvent.x, y: mouseEvent.y })
					createRadarModel({ x: mouseEvent.x, y: mouseEvent.y }, () => {
						globalStore.Player.addradarCount()
						audio.shoucang.play()
					})
				}, 300)
			}, 200)
		},
		createMagnifier(mouseEvent: vector2) {
			const { Skill } = globalStore
			audio.surprise.play()
			console.log(`获得放大镜1个...`)
			createChestModel({ x: mouseEvent.x, y: mouseEvent.y }, (ani) => {
				console.log(1)
			})
			setTimeout(() => {
				if (this.chest.useWhenGetting) Skill.useRandomGetGoodsEffect()
				//  创建星星模型
				createStarModel({ x: mouseEvent.x, y: mouseEvent.y })
				setTimeout(() => {
					//  创建星星模型
					createPuzzleBGModel({ x: mouseEvent.x, y: mouseEvent.y })
					createBiggerModel({ x: mouseEvent.x, y: mouseEvent.y }, () => {
						globalStore.Player.addbiggerCount()
						audio.shoucang.play()
					})
				}, 300)
			}, 200)
		},
		immCreatePuzzle(data: anyObject) {
			//  立即创建碎片和拼图
		},
		createPuzzle(mouseEvent: vector2, data: anyObject) {
			const { Skill, Customs, Player } = globalStore
			console.log(`获得拼图碎片1个...`)
			audio.surprise.play()
			createChestModel({ x: mouseEvent.x, y: mouseEvent.y }, (ani) => {
				console.log(1)
			})
			setTimeout(() => {
				if (this.chest.useWhenGetting) Skill.useRandomGetGoodsEffect()
				//  创建星星模型
				createStarModel({ x: mouseEvent.x, y: mouseEvent.y })
				setTimeout(() => {
					//  创建星星模型
					createPuzzleBGModel({ x: mouseEvent.x, y: mouseEvent.y })
					//const part = random(1, 9)
					createPuzzlePieceModel({ sceneId: Customs.curlevel, part: data.fragmentInfo.part, puzzleId: data.fragmentInfo.puzzleId }, { x: mouseEvent.x, y: mouseEvent.y }, async () => {
						const puzzleNo = parseInt((data.fragmentInfo.part - 1) / 9 + '') + 1
						const domIndexList = new Array(9).fill(true)
						const has9fragment = domIndexList.every((item, index) => document.querySelector(`.fragment_${index + 1 + (puzzleNo - 1) * 9}`))
						const hasPuzzles = document.querySelector(`.puzzle_${puzzleNo + 3}`)
						//  有9个碎片和没有拼图,那么就生成拼图
						if (has9fragment && !hasPuzzles) {
							const { data: compositeData } = (await game_v1_puzzle_composite({ puzzleId: data.fragmentInfo.puzzleId })) as anyObject
							createPuzzleBG2Model()
							audio.puzzle_ok.play()
							setTimeout(() => {
								audio.surprise.play()
							}, 700)
							createPuzzleModel({ sceneId: Customs.curlevel, puzzleNo: puzzleNo }, () => {
								createPuzzleGlossModel({ id: 1, puzzleNo: puzzleNo })
								//  开启传送门
								if (data.nextLevel) {
									Customs.unlockPortal()
								}
							})
						}
						const { data: infodata } = (await game_v1_map_info({})) as anyObject
						infodata.scenes.forEach((item: anyObject) => {
							const puzzleslist = item.puzzles.sort((m: anyObject, n: anyObject) => {
								if (m.no < n.no) return -1
								else if (m.no > n.no) return 1
								else return 0
							})
							Object.assign(Player.puzzle.list, { [item.sceneId]: puzzleslist })
						})
					})
				}, 300)
			}, 200)
		},
		createCoin(mouseEvent: vector2, data: anyObject) {
			const { Skill } = globalStore
			console.log(`获得金币1个...`)
			createChestModel({ x: mouseEvent.x, y: mouseEvent.y }, (ani) => {
				console.log(1)
			})
			setTimeout(() => {
				if (this.chest.useWhenGetting) Skill.useRandomGetGoodsEffect()
				setTimeout(() => {
					createCoinModel({ x: mouseEvent.x, y: mouseEvent.y })
					setTimeout(() => {
						audio.shoucang.play()
						globalStore.Player.addCoinCount(data.coin)
					}, 2.7 * 500)
				}, 100)
			}, 200)
		},
	},
})
function useRandomGetGoodsSkill() {
	throw new Error('Function not implemented.')
}
