import { defineStore } from 'pinia'
import { Flip } from 'number-flip'
import { nextTick, PropType } from 'vue'
import { createBiggerModel, immCreatePuzzleModel, immCreatePuzzlePieceModel } from '@/common/sequence_frame'
import { globalStore } from '../store'
import { Toast } from 'vant'
import random from 'lodash/random'
import { game_v1_level, game_v1_map_info } from '@/common/api'
import { audio } from '@/common/audio'
export const Customs = defineStore('Customs', {
	//persist: true,
	state: () => ({
		curlevel: '16842753',
		door: [-150.218, 2.8, 42.36],
		sid: '',
		mapList: [
			{ sceneId: '16842753', prevSceneId: 'unlock', area: 'a', imgname: 'map_Shanghai', door: [0, -13, 76.86] },
			{ sceneId: '17235969', prevSceneId: '16842753', area: 'b', imgname: 'map_Vatican', door: [-46.882, 0, 76.7] },
			{ sceneId: '17170433', prevSceneId: '17235969', area: 'c', imgname: 'map_NewYork', door: [-28.327, -30, 42.36] },
			{ sceneId: '17432577', prevSceneId: '17170433', area: 'd', imgname: 'map_Berlin', door: [34.134, 0, 88.04] },
			{ sceneId: '17104897', prevSceneId: '17432577', area: 'e', imgname: 'map_Paris', door: [-179.218, 0, 110.45] },
			{ sceneId: '17039361', prevSceneId: '17104897', area: 'f', imgname: 'map_Prague', door: [91.2, 0, 71.24] },
			{ sceneId: '17301505', prevSceneId: '17039361', area: 'g', imgname: 'map_Venice', door: [65.738, 0, 55.92] },
			{ sceneId: '16777217', prevSceneId: '17301505', area: 'h', imgname: 'map_Iceland', door: [79.7, 0, 65.91] },
			{ sceneId: '17367041', prevSceneId: '16777217', area: 'i', imgname: 'map_Zurich', door: [-98.443, 0, 78.66] },
		],
		portalTime: 0,
		lockTime: 0,
		portalEffectsTime: 0,
	}),
	actions: {
		getNextCustoms() {
			let curindex = 0
			this.mapList.forEach((item, index) => {
				if (item.sceneId === this.curlevel) curindex = index
			})
			return this.mapList[Math.min(curindex + 1, Math.max(0, this.mapList.length - 1))]
		},
		setCustoms(sceneId: string, door: anyArray) {
			const { Krpano, Player, Unit, Customs } = globalStore
			//  切换关卡特效
			this.changeCustomsEffects()
			//  加载全景
			Krpano.loadPanoXml(`${sceneId}`, async () => {
				const { data: leveldata } = (await game_v1_level({ sceneId: sceneId })) as anyObject
				const { data: infodata } = (await game_v1_map_info({})) as anyObject
				;(document.querySelector('.ui__footer-bar .ui__puzzle-container') as HTMLElement).innerHTML = ''
				//  创建碎片
				setTimeout(() => {
					leveldata.fragments.forEach((item: anyObject) => {
						immCreatePuzzlePieceModel({ sceneId: sceneId, part: item.part, puzzleId: item.puzzleId })
					})
				}, 10)

				//  创建拼图

				infodata.scenes.forEach((item: anyObject) => {
					const puzzleslist = item.puzzles.sort((m: anyObject, n: anyObject) => {
						if (m.no < n.no) return -1
						else if (m.no > n.no) return 1
						else return 0
					})
					Object.assign(Player.puzzle.list, { [item.sceneId]: puzzleslist })
					setTimeout(() => {
						if (sceneId === item.sceneId) {
							item.puzzles.forEach((puzzleItem: anyObject) => {
								immCreatePuzzleModel({ sceneId: item.sceneId, puzzleNo: puzzleItem.no })
							})
						}
					}, 10)
				})

				Unit.thief.found = leveldata.found
				//  设置当前关卡id和索引
				this.curlevel = sceneId
				//  创建小偷
				Unit.createThief()
				//  创建传送门  最后一关不创建传送门
				if (this.mapList[this.mapList.length - 1].sceneId !== this.curlevel) {
					Customs.createPortal()
				}

				//setTimeout(() => {
				//  获取已抓数量和总数
				const thiefList =
					(Krpano.run
						.get('hotspot')
						.getArray()
						.filter((thief: anyObject) => /hs_/.test(thief.name)) as anyArray) || []
				console.log('thiefListthiefList', thiefList.length)
				Object.assign(Player.thief.list, { [sceneId]: { count: Player.thief.list[sceneId]?.count || 0, total: thiefList.length } })
				//Player.thief.instance.flipTo({ to: leveldata.found.length, duration: 0.4, direct: false })
				Player.thief.totalInstance.flipTo({ to: Unit.thief.max, duration: 0, direct: false })
				//}, 1000)
			})
		},
		changeCustomsEffects() {
			const { Krpano, Player, Unit } = globalStore
			//  播放特效
			Krpano.effects('brightness', '1', 0, null)
			setTimeout(() => {
				Krpano.effects('brightness', '0', 0.3, null)
				Krpano.effects('ripple', '0.5', 0, null)
				setTimeout(() => {
					Krpano.effects('ripple', '0', 1.5, null)
					Krpano.effects('split', '0', 0.5, 'easeInQuad')
				}, 50)
			}, 500)
		},
		createPortal() {
			const { Krpano, Player, Unit } = globalStore
			const { addHotspot, run } = globalStore.Krpano
			const { embedpano, removepano } = window
			const self = this as typeof this
			const selfkrpanoObj = null
			clearTimeout(this.portalTime)
			clearTimeout(this.lockTime)
			clearTimeout(this.portalEffectsTime)
			addHotspot('portal', {
				//keep: true,
				width: '540',
				height: '540',
				ath: self.door[0],
				atv: self.door[1],
				scale: (self.door[2] / 120) * 0.5,
				enabled: false,
				capture: false,
				distorted: true,
				renderer: 'css3d',
				depth: 5000,
				zoom: true,
				url: require('@/assets/img/portal_bg.png'),
				alpha: '0',
				onloaded: '',
			})
			this.portalTime = setTimeout(() => run.call(`tween(hotspot[portal].alpha,1,2)`), 1000)
			if (!Player.puzzle.list[this.curlevel]) {
				addHotspot('lock', {
					//keep: true,
					width: '256',
					height: '256',
					ath: self.door[0],
					atv: self.door[1],
					scale: (self.door[2] / 120) * 0.5,
					enabled: false,
					capture: false,
					distorted: true,
					renderer: 'css3d',
					depth: 5000,
					zoom: true,
					url: require('@/assets/frame/unlock_ani_256x256.png'),
					onloaded: 'frameAni(256,256, 0)',
					alpha: '0',
				})
				this.lockTime = setTimeout(() => run.call(`tween(hotspot[lock].alpha,1,2)`), 1000)
			}

			// 创建传送门特效
			addHotspot('portalEffects', {
				//keep: true,
				width: '720',
				height: '540',
				ath: self.door[0],
				atv: self.door[1],
				scale: self.door[2] / 120,
				torigin: 'view',
				capture: false,
				renderer: 'css3d',
				depth: 5000,
				alpha: '0',
				zoom: true,
				distorted: true,
				url: require('@/assets/frame/red_Door_360x270.png'),
				onloaded: 'frameAni(360,270, 30)',
				onclick() {
					//  有锁就返回,不做任何操作
					if (run.get('hotspot[lock]')) return Toast('尚未解锁，请加油完成前置关卡！')
					const portal = run.get('hotspot[portal].sprite') as HTMLElement
					const timer = setInterval(() => {
						const { top, bottom, left, right, height, width } = portal.getBoundingClientRect()
						console.log(top, bottom - height, left, right - width)
						if (top < 0 && bottom - height < 0 && left < 0 && right - width < 0) {
							clearInterval(timer)
							run.call('stoplookto()')
							removepano('portalObject')
							run.call(`removehotspot(sprite)`)
							run.call(`removehotspot(portalEffects)`)
							run.call(`removehotspot(lock)`)
							const data = self.getNextCustoms()
							self.setCustoms(data.sceneId, data.door)
						}
					}, 33)
					const portalfov = run.get('game_setting.portalFov')
					const differValue = (120 - portalfov) / 3
					const step = [120 - differValue * 1.5, 120 - differValue * 3, 120 - differValue * 3.3]
					const fovto = step[2]

					const { Krpano, Player, Unit } = globalStore
					const { width: bodyWidth } = document.body.getBoundingClientRect()
					window.krpanoObj.__createPortal__ = () => {
						Krpano.effects('ripple', String((bodyWidth / 375) * 0.3), 0, null)
						//Krpano.effects('brightness', '0.5', 1.5, 'easeInQuad')
						Krpano.effects('split', '2', 0.5, 'easeInQuad')
					}
					run.call(`
            lookto(${run.get('hotspot[portalEffects].ath')},${run.get('hotspot[portalEffects].atv')},${120},default,true,true,
            jscall(window.krpanoObj.__createPortal__());
            lookto(${run.get('hotspot[portalEffects].ath')},${run.get('hotspot[portalEffects].atv')},${fovto},tween(linear,1),true,true)
            )`)
				},
			})
			this.portalEffectsTime = setTimeout(() => run.call(`tween(hotspot[portalEffects].alpha,1,2)`), 1000)

			setTimeout(() => {
				embedpano({
					xml: `%HTMLPATH%/xml/city/${self.getNextCustoms().sceneId}.xml`, // 主配置文件
					target: run.get('hotspot[portal].sprite'),
					id: 'portalObject', // 当前全景id，javascript接口调用会使用此id
					html5: 'only+webgl', // 自动检测是否用h5打开
					mobilescale: 1.0,
					passQueryParameters: true,
					//回调函数，获取Krpano 对象
					onready: (krpanoObj: anyObject) => {
						selfkrpanoObj == krpanoObj
						const portalObject = document.querySelector('#portalObject') as HTMLElement
						portalObject.style.overflow = 'hidden'
						portalObject.style.borderRadius = '50%'
						const portal = run.get('hotspot[portal].sprite') as HTMLElement
						portal.style.overflow = 'hidden'
						portal.style.borderRadius = '50%'
					},
				})
			}, 200)
		},
		unlockPortal() {
			const self = this as typeof this
			const { addHotspot, run } = globalStore.Krpano
			const fov = run.get('view.fov')
			const hlookat = run.get('view.hlookat')
			const vlookat = run.get('view.vlookat')
			//
			run.set(`control.usercontrol`, `off`)
			setTimeout(() => {
				run.set(`control.usercontrol`, `all`)
			}, 5400)
			run.call(`
          lookto(${run.get('hotspot[portalEffects].ath')},${run.get('hotspot[portalEffects].atv')},${120},tween(easeinoutsine,0.8),true,true,
            lookto(${run.get('hotspot[portalEffects].ath')},${run.get('hotspot[portalEffects].atv')},${this.door[2] * 0.7},tween(easeinoutsine,0.8),true,true)
          )
          `)
			setTimeout(() => {
				audio.guoguan.play()
				run.call(`removehotspot(lock)`)
				addHotspot('lock', {
					//keep: true,
					width: '256',
					height: '256',
					ath: self.door[0],
					atv: self.door[1],
					scale: (self.door[2] / 120) * 0.5,
					enabled: false,
					capture: false,
					distorted: true,
					renderer: 'css3d',
					depth: 5000,
					zoom: true,
					url: require('@/assets/frame/unlock_ani_256x256.png'),
					onloaded: 'frameAni(256,256, 33,once)',
					alpha: '1',
				})
				//  显示箭头
				setTimeout(() => {
					globalStore.Krpano.createLineAndArrow(this.door[0], this.door[1])
				}, 500)
				//  删除锁
				setTimeout(() => {
					run.call(`tween(hotspot[lock].alpha,0,0.5,easeinoutback,removehotspot(lock));`)
				}, 2000)
			}, 1800)
			//  还原坐标
			setTimeout(() => {
				run.call(`
          lookto(${hlookat},${vlookat},${120},tween(easeinoutsine,0.8),true,true,
          lookto(${hlookat},${vlookat},${fov},tween(easeinoutsine,0.8),true,true)
          )
          `)
			}, 3800)
		},
	},
})
