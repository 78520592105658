import { defineStore } from 'pinia'
import { Flip } from 'number-flip'
import { nextTick, PropType } from 'vue'
import { createBiggerModel, immCreatePuzzleModel, immCreatePuzzlePieceModel } from '@/common/sequence_frame'
import { globalStore } from '../store'
import { Toast, Dialog } from 'vant'
import random from 'lodash/random'
import { game_v1_level, game_v1_map_info } from '@/common/api'
import { audio } from '@/common/audio'
export const Ad = defineStore('Ad', {
	//persist: true,
	state: () => ({
		timer: 0,
		second: 15,
		show: false,
		progressStep: 1,
		type: '',
	}),
	actions: {
		toggle(state: boolean, type?: string, desc?: '用户指引弹框开关') {
			// state true开 false关
			console.log('state', state, type)
			this.show = state
			if (state) {
				this.type = type || ''
				this.progressStep = 1
				this.second = 5
			} else {
				if (this.second == 0) {
					this.second = 5
				}
			}
		},
		adTips() {
			const { Player } = globalStore
			if (Player.radar.count || Player.bigger.count) {
				null
			} else {
				console.log('提示查看广告')
				this.second = 5
				this.toggle(true)
			}
		},
	},
})
