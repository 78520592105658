import { defineStore } from 'pinia'
import { Flip } from 'number-flip'
import { nextTick, PropType } from 'vue'
import random from 'lodash/random'
import { globalStore } from '../store'
import { user_v1_me } from '@/common/api'
import { Toast } from 'vant'
import moment from 'moment'
//  玩家信息
export const Player = defineStore('Player', {
	//persist: true,
	state: () => ({
		audio: true,
		numIconFont: ['&#xe625;', '&#xe622;', '&#xe621;', '&#xe624;', '&#xe623;', '&#xe620;', '&#xe61f;', '&#xe626;', '&#xe628;', '&#xe627;'],
		username: '',
		avatar: '',
		//playTime: '',
		playTime: {
			minute: 0,
			second: 0,
			maxMinute: 0,
			maxSecond: 10,
			instanceMinute: {} as anyObject,
			instanceSecond: {} as anyObject,
			timer: 0,
		},
		// 玩家等级
		level: {
			count: 0,
			instance: {} as anyObject,
		},
		//  金币
		gold: {
			count: 0,
			instance: {} as anyObject,
		},
		//  小偷
		thief: {
			list: {} as anyObject,
			instance: {} as anyObject,
			totalInstance: {} as anyObject,
		},
		//  关卡
		customs: {
			count: [],
		},
		//  雷达
		radar: {
			name: 'radar',
			count: 0,
			minute: 0,
			second: 0,
			maxMinute: 0,
			maxSecond: 0,
			instanceMinute: {} as anyObject,
			instanceSecond: {} as anyObject,
			start: false,
		},
		//  放大镜
		bigger: {
			name: 'bigger',
			count: 0,
			minute: 0,
			second: 0,
			maxMinute: 0,
			maxSecond: 3,
			instanceMinute: {} as anyObject,
			instanceSecond: {} as anyObject,
			start: false,
			timer: 0,
		},
		//  完整拼图
		puzzle: {
			name: 'puzzle',
			list: {} as anyObject,
		},
		//  拼图碎片
		puzzlePiece: {
			name: 'puzzlePiece',
			list: [] as Array<{ sceneId: number; puzzle: number; part: Array<number> }>,
		},
	}),
	actions: {
		async init() {
			const { data } = (await user_v1_me({})) as anyObject
			const { name, avatar, level, gold, playTime } = data.user
			this.username = name
			this.avatar = avatar
			this.level.count = level
			this.gold.count = gold
			this.playTime.maxMinute = moment.duration(playTime).minutes()
			this.playTime.maxSecond = moment.duration(playTime).seconds()
			this.gameTimer()
			if (this.radar.start) this.useRadarGoods({ recovery: true })
			if (this.bigger.start) this.usebiggeGoods({ recovery: true })
		},
		//恢复计时
		async recovery() {
			const { data } = (await user_v1_me({})) as anyObject
			const { name, avatar, level, gold, playTime } = data.user
			this.username = name
			this.avatar = avatar
			this.level.count = level
			this.gold.count = gold
			this.playTime.maxMinute = moment.duration(playTime).minutes()
			this.playTime.maxSecond = moment.duration(playTime).seconds()
			this.gameTimer()
		},
		useRadarGoods({ recovery = false }: { recovery?: boolean } = {}) {
			const { Skill } = globalStore
			console.log(this.radar.start, this.radar.count)
			if ((this.radar.start && recovery) || (!this.radar.start && this.radar.count > 0)) {
				this.radar.count = this.radar.count > 0 ? this.radar.count - 1 : 0
				//this.useGoods({ skillData: this.radar })
				Skill.useRadarEffect()
			}
		},
		usebiggeGoods({ recovery = false }: { recovery?: boolean } = {}) {
			const { Skill } = globalStore
			console.log(this.bigger.start, this.bigger.count)
			if ((this.bigger.start && recovery) || (!this.bigger.start && this.bigger.count > 0)) {
				this.bigger.count = this.bigger.count > 0 ? this.bigger.count - 1 : 0
				this.useGoods({ skillData: this.bigger })
				Skill.usebiggeEffect()
			} else {
				Toast('道具生效中，稍后再试')
			}
		},
		useGoods({ skillData }: { skillData: anyObject } = { skillData: {} }) {
			skillData.start = true
			if (!skillData.minute && !skillData.second) {
				skillData.minute = skillData.maxMinute
				skillData.second = skillData.maxSecond
			}
			nextTick(() => {
				if (!skillData.instanceMinute.flipTo) skillData.instanceMinute = new Flip({ node: document.querySelector(`.ui__${skillData.name}--timer .ui__frame-count`), from: 99, to: skillData.minute, duration: 0, direct: false, delay: 0, systemArr: this.numIconFont })
				else skillData.instanceMinute.flipTo({ to: skillData.minute, duration: 0.4, direct: false })
				if (!skillData.instanceSecond.flipTo) skillData.instanceSecond = new Flip({ node: document.querySelector(`.ui__${skillData.name}--timer .ui__frame-total`), from: 99, to: skillData.second, duration: 0, direct: false, delay: 0, systemArr: this.numIconFont })
				else skillData.instanceSecond.flipTo({ to: skillData.second, duration: 0.4, direct: false })
				const instanceMinute = skillData.instanceMinute as anyObject
				const instanceSecond = skillData.instanceSecond as anyObject

				//instanceMinute.flipTo({ to: skillData.minute, direct: false, duration: 0 })
				//instanceSecond.flipTo({ to: skillData.second, direct: false, duration: 0 })

				//  倒计时
				this.bigger.timer = setInterval(() => {
					if (skillData.second === 0) {
						if (skillData.minute === 0 && skillData.second === 0) {
							skillData.start = false
							clearInterval(this.bigger.timer)
							this.bigger.timer = 0
							return
						}
						skillData.second = 60
						skillData.minute = skillData.minute - 1
						skillData.second = skillData.second - 1
						instanceMinute.flipTo({ to: skillData.minute, duration: 0.4, direct: false })
						instanceSecond.flipTo({ to: skillData.second, duration: 0.4, direct: false })
					} else {
						skillData.second = skillData.second - 1
						instanceSecond.flipTo({ to: skillData.second, duration: 0.4, direct: false })
					}
				}, 1000)
			})
		},
		gameTimer() {
			const skillData = this.playTime
			if (!skillData.minute && !skillData.second) {
				skillData.minute = skillData.maxMinute
				skillData.second = skillData.maxSecond
			}
			nextTick(() => {
				if (!skillData.instanceMinute.flipTo) skillData.instanceMinute = new Flip({ node: document.querySelector(`.ui__timer .ui__frame-count`), from: 99, to: skillData.minute, duration: 0, direct: false, delay: 0, systemArr: this.numIconFont })
				else skillData.instanceMinute.flipTo({ to: skillData.minute, duration: 0.4, direct: false })
				if (!skillData.instanceSecond.flipTo) skillData.instanceSecond = new Flip({ node: document.querySelector(`.ui__timer .ui__frame-total`), from: 99, to: skillData.second, duration: 0, direct: false, delay: 0, systemArr: this.numIconFont })
				else skillData.instanceSecond.flipTo({ to: skillData.second, duration: 0.4, direct: false })

				const instanceMinute = skillData.instanceMinute as anyObject
				const instanceSecond = skillData.instanceSecond as anyObject

				//instanceMinute.flipTo({ to: skillData.minute, direct: false, duration: 0 })
				//instanceSecond.flipTo({ to: skillData.second, direct: false, duration: 0 })

				//  倒计时
				this.playTime.timer = setInterval(() => {
					if (skillData.second === 59) {
						skillData.second = 0
						skillData.minute = skillData.minute + 1
						instanceMinute.flipTo({ to: skillData.minute, duration: 0.4, direct: false })
						instanceSecond.flipTo({ to: skillData.second, duration: 0.4, direct: false })
					} else {
						skillData.second = skillData.second + 1
						instanceSecond.flipTo({ to: skillData.second, duration: 0.4, direct: false })
					}
				}, 1000)
			})
		},
		addlevelCount() {
			this.level.count++
			this.gold.instance.flipTo({ to: this.level.count, duration: 0.4, direct: false })
		},
		addradarCount() {
			this.radar.count++
		},
		addbiggerCount() {
			this.bigger.count++
		},
		addPuzzleCount() {
			this.bigger.count++
			this.addlevelCount()
		},
		addCoinCount(goldcount: number) {
			this.gold.count = this.gold.count + goldcount
			this.gold.instance.flipTo({ to: this.gold.count, duration: 0.4, direct: false })
		},

		//  已抓小偷+1
		addCatchThief() {
			const curlevel = globalStore.Customs.curlevel
			console.log('this.thief.list[curlevel]', Object.keys(this.thief.list), curlevel)
			this.thief.instance.flipTo({ to: ++this.thief.list[curlevel], duration: 0.4, direct: false })
		},
		//  重置已抓小偷
		resetCaughtThief(count: number) {
			const curlevel = globalStore.Customs.curlevel
			this.thief.list[curlevel] = count
			if (this.thief.instance) this.thief.instance.flipTo({ to: count, duration: 0.4, direct: false })
		},
		//  重置小偷总数
		resetThiefTotal(total: number) {
			const curlevel = globalStore.Customs.curlevel
			this.thief.list[curlevel].total = total
			this.thief.instance.flipTo({ to: total, duration: 0.4, direct: false })
		},
		//  获取小偷数量
		getThiefCount() {
			const curlevel = globalStore.Customs.curlevel
			return this.thief.list[curlevel]
		},
		//  获取小偷总数
		getThiefTotal() {
			const curlevel = globalStore.Customs.curlevel
			return this.thief.list[curlevel].total
		},
	},
})
