export const titleCase = (str: string) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()

export const isEmpty = (obj: anyObject) => Object.keys(obj).length === 0

export const getDomAxis = (dom: HTMLElement) => {
	const { left, top, width, height } = dom.getBoundingClientRect()
	const { scrollLeft, scrollTop } = document.documentElement
	return {
		x: left + scrollLeft,
		y: top + scrollTop,
		width: width,
		height: height,
	}
}

export const vector2 = (x: number, y: number) => {
	return { x, y }
}

export const createInterval = (cb: () => void, second: number) => {
	let timer = 0
	const play = () => {
		clearInterval(timer)
		timer = setInterval(() => cb(), second)
	}
	//  恢复
	const restore = () => {
		timer = setInterval(() => cb(), second)
	}
	//  暂停
	const pause = () => {
		clearInterval(timer)
	}
	return {
		play,
		restore,
		pause,
	}
}
