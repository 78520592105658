import anime, { remove } from 'animejs'
import { getDomAxis } from './tools'
type AnimeTarget = string | object | HTMLElement | SVGElement | NodeList | null
interface AnimeInstance extends anime.AnimeInstance {
	remove(targets: AnimeTarget | ReadonlyArray<AnimeTarget>): void
}
type CallBackType = (anim: anime.AnimeInstance) => void
type UnitAni = { baseurl?: string; v2: vector2; width: number; height: number; startPositionY?: number; maxheight: number; scale: number; imgurl: string; reverse?: boolean; parentDom?: Element }

export const unitAni = async (params: UnitAni) => {
	const { v2, width, height, maxheight, scale, imgurl, startPositionY = 0, reverse = false, baseurl = '', parentDom = document.body } = params
	const dom: HTMLElement = document.createElement('div')
	dom.style.width = width * scale + 'px'
	dom.style.height = height * scale + 'px'
	dom.style.backgroundSize = `${width * scale}px  ${maxheight * scale}px`
	dom.style.backgroundImage = `url(${require(`@/assets/frame/` + imgurl)})`
	dom.style.backgroundPositionY = startPositionY * scale + 'px'
	dom.style.position = 'absolute'
	dom.style.pointerEvents = 'none'
	if (v2.x > 0) dom.style.left = v2.x - (width * scale) / 2 + 'px'
	if (v2.x < 0) dom.style.right = -v2.x - (width * scale) / 2 + 'px'
	if (v2.y > 0) dom.style.top = v2.y - (height * scale) / 1.2 + 'px'
	if (v2.y < 0) dom.style.bottom = -v2.y - (height * scale) / 1.2 + 'px'

	dom.dataset['backgroundPositionY'] = reverse ? '-' : '+' + String((maxheight - startPositionY) * scale) + 'px'
	dom.dataset['steps'] = String(parseInt(maxheight / height + ''))
	parentDom.appendChild(dom)

	return dom
}

type ImgModelType = { imgurl: string; style?: anyObject; parentDom?: Element }
export const createImgModel = (params: ImgModelType) => {
	const { style = {}, imgurl, parentDom = document.body } = params
	const dom: HTMLElement = document.createElement('div')
	dom.style.position = 'fixed'
	dom.style.zIndex = '1'
	dom.style.backgroundImage = `url(${require(`@/assets/` + imgurl)})`
	for (const [key, val] of Object.entries(style)) (dom.style as anyObject)[key] = val
	parentDom.appendChild(dom)
	return dom
}

/* 序列帧动画集合 */
export const biggerChest = async (v2: vector2) => {
	const unitdom = await unitAni({ v2, width: 204, height: 226, maxheight: 3164, scale: 0.3, imgurl: 'bigger_ani_204x226.png' })
	anime({
		targets: unitdom,
		backgroundPositionY: unitdom.dataset['backgroundPositionY'],
		direction: 'reverse',
		loop: 5,
		duration: 0.5 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
}

export const createChestModel = async (v2: vector2, complete?: (anim: anime.AnimeInstance) => void) => {
	const unitdom = await unitAni({ v2, width: 256, height: 256, maxheight: 6144, scale: 0.4, imgurl: 'chest_ani_256x256.png' })
	return anime({
		targets: unitdom,
		backgroundPositionY: unitdom.dataset['backgroundPositionY'],
		direction: 'reverse',
		loop: 1,
		duration: 1.2 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			if (complete) complete(anim)
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
}

export const createPuzzleBGModel = async (v2: vector2) => {
	const unitdom = await unitAni({ v2, width: 256, height: 256, maxheight: 10240, scale: 0.5, imgurl: 'puzzle_1BG_256x256.png' })
	;(unitdom as HTMLElement).style.opacity = '0'
	anime({
		targets: unitdom,
		opacity: { value: 1, duration: 500, delay: 0 },
		backgroundPositionY: unitdom.dataset['backgroundPositionY'],
		direction: 'reverse',
		top: [{ value: '-=60px', duration: 0, delay: 0 }],
		scale: [{ value: '1', duration: 0, delay: 0 }],
		loop: 1,
		duration: 1.2 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
}

export const createPuzzleBG2Model = async () => {
	const unitdom = await unitAni({ v2: { x: 0, y: 0 }, width: 256, height: 256, maxheight: 10752, scale: 3, imgurl: 'puzzle_2BG_256x256.png' })
	unitdom.style.top = 'calc(50% - 100px)'
	unitdom.style.left = '50%'
	unitdom.style.transform = 'translate(-50%,-50%)'
	;(unitdom as HTMLElement).style.opacity = '0'
	anime({
		targets: unitdom,
		opacity: { value: 1, duration: 500, delay: 0 },
		backgroundPositionY: unitdom.dataset['backgroundPositionY'],
		direction: 'reverse',
		scale: [{ value: '1', duration: 0, delay: 0 }],
		loop: 1,
		duration: 1.2 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
}

export const createPuzzle_ani = async (v2: vector2) => {
	const unitdom = await unitAni({ v2, width: 256, height: 256, maxheight: 7680, scale: 0.3, imgurl: 'puzzle_ani_256x256.png' })
	anime({
		targets: unitdom,
		backgroundPositionY: unitdom.dataset['backgroundPositionY'],
		direction: 'reverse',
		loop: 5,
		duration: 0.5 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
}

//  礼花动画
export const createStarModel = async (v2: vector2, complete?: CallBackType) => {
	const unitdom = await unitAni({ v2, width: 256, height: 256, maxheight: 5376, scale: 0.6, imgurl: 'star_ani_256x256.png' })
	anime({
		targets: unitdom,
		backgroundPositionY: unitdom.dataset['backgroundPositionY'],
		top: [{ value: '-=40px', duration: 1000, delay: 0 }],
		direction: 'reverse',
		loop: 1,
		duration: 1.2 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			if (complete) complete(anim)
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
}
//  创建变大道具
export const createBiggerModel = async (v2: vector2, complete?: (anim: anime.AnimeInstance) => void) => {
	const w = 159 / 3
	const h = 190 / 3
	const unitdom = createImgModel({
		imgurl: `img/bigger_btn_1.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
			left: v2.x - w / 2 + 'px',
			top: v2.y - h / 2 + 'px',
		},
	})
	unitdom.style.transform = 'scale(0.2)'
	unitdom.style.opacity = '0.3'
	unitdom.style.zIndex = '20'
	// 缩小3倍  分三块  再切一半

	unitdom.style.transformOrigin = `center center`

	const { x: tx, y: ty } = getDomAxis(document.querySelector('.ui__bigger') as HTMLElement)

	anime({
		targets: unitdom,
		left: [
			{ duration: 0, delay: 1000 },
			{ value: tx + 'px', duration: 2000, delay: 0 },
		],
		top: [
			{ value: `-=${h * 2}px`, duration: 0, delay: 1000 },
			{ value: ty + 'px', duration: 2000, delay: 0 },
		],
		scale: [
			{ value: '1.3', duration: 500, delay: 0, easing: 'easeOutBack' },
			{ value: '1', duration: 1500, delay: 300 },
		],
		opacity: [{ value: '1', duration: 1000, delay: 0, easing: 'easeOutBack' }],
		loop: 1,
		easing: 'easeInOutExpo',
		duration: 6 * 1000,
		complete: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.style.filter = 'contrast(100%)'
			const animAgain = anime({
				targets: unitdom,
				opacity: { value: '0', duration: 1000, delay: 100, easing: 'easeOutBack' },
				loop: 1,
				easing: 'easeOutBack',
				duration: 0.5 * 1000,
				complete: function (anim: anime.AnimeInstance) {
					;(animAgain as AnimeInstance).remove(unitdom)
					unitdom.remove()
				},
			})
		},
	})
	return unitdom
}
//  金币 jinbi_coin
export const createCoinModel = async (v2: vector2, complete?: (anim: anime.AnimeInstance) => void) => {
	const w = 86 / 3
	const h = 113 / 3
	const unitdom = createImgModel({
		imgurl: `img/jinbi_coin.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
			left: v2.x - w / 2 + 'px',
			top: v2.y - h / 2 + 'px',
		},
	})
	unitdom.style.transform = 'scale(0.2)'
	unitdom.style.opacity = '0.3'
	unitdom.style.zIndex = '20'
	// 缩小3倍  分三块  再切一半

	unitdom.style.transformOrigin = `center center`

	const { x: tx, y: ty } = getDomAxis(document.querySelector('.ui__gold') as HTMLElement)

	anime({
		targets: unitdom,
		left: [
			{ duration: 0, delay: 500 },
			{ value: tx + 'px', duration: 1000, delay: 0 },
		],
		top: [
			{ value: `-=${h * 2.25}px`, duration: 0, delay: 500 },
			{ value: ty + 'px', duration: 1000, delay: 0 },
		],
		scale: [
			{ value: '1.3', duration: 250, delay: 0, easing: 'easeOutBack' },
			{ value: '1', duration: 750, delay: 150 },
		],
		opacity: [{ value: '1', duration: 500, delay: 0, easing: 'easeOutBack' }],
		loop: 1,
		easing: 'easeInOutExpo',
		complete: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.remove()
		},
	})
	return unitdom
}
//  创建雷达道具
export const createRadarModel = async (v2: vector2, complete?: (anim: anime.AnimeInstance) => void) => {
	const w = 159 / 3
	const h = 190 / 3
	const unitdom = createImgModel({
		imgurl: `img/radar_btn_1.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
			left: v2.x - w / 2 + 'px',
			top: v2.y - h / 2 + 'px',
		},
	})
	unitdom.style.transform = 'scale(0.2)'
	unitdom.style.opacity = '0.3'
	unitdom.style.zIndex = '20'
	// 缩小3倍  分三块  再切一半

	unitdom.style.transformOrigin = `center center`

	const { x: tx, y: ty } = getDomAxis(document.querySelector('.ui__radar') as HTMLElement)

	anime({
		targets: unitdom,
		left: [
			{ duration: 0, delay: 1000 },
			{ value: tx + 'px', duration: 2000, delay: 0 },
		],
		top: [
			{ value: `-=${h * 2}px`, duration: 0, delay: 1000 },
			{ value: ty + 'px', duration: 2000, delay: 0 },
		],
		scale: [
			{ value: '1.3', duration: 500, delay: 0, easing: 'easeOutBack' },
			{ value: '1', duration: 1500, delay: 300 },
		],
		opacity: [{ value: '1', duration: 1000, delay: 0, easing: 'easeOutBack' }],
		loop: 1,
		easing: 'easeInOutExpo',
		duration: 6 * 1000,
		complete: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
			;(anim as AnimeInstance).remove(unitdom)
			unitdom.style.filter = 'contrast(100%)'
			const animAgain = anime({
				targets: unitdom,
				opacity: { value: '0', duration: 1000, delay: 100, easing: 'easeOutBack' },
				loop: 1,
				easing: 'easeOutBack',
				duration: 0.5 * 1000,
				complete: function (anim: anime.AnimeInstance) {
					;(animAgain as AnimeInstance).remove(unitdom)
					unitdom.remove()
				},
			})
		},
	})
	return unitdom
}
//  创建拼图
export const createPuzzleModel = async (fragments: { sceneId: string; puzzleNo: number }, complete?: (anim: anime.AnimeInstance) => void) => {
	const { sceneId, puzzleNo } = fragments
	const w = 512 / 3.525
	const h = 523 / 3.525
	const { width, height } = getDomAxis(document.querySelector('#app') as HTMLElement)
	console.log(width, height)
	const unitdom = createImgModel({
		parentDom: document.querySelector('.ui__footer-bar .ui__puzzle-container') as Element,
		imgurl: `clips/${sceneId}/puzzle_${puzzleNo}.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
			left: width / 2 - w / 2 + 'px',
			top: height / 2 - h / 2 - 100 + 'px',
		},
	})
	unitdom.className = `puzzle_${puzzleNo}`
	const unitdomMask = createImgModel({
		imgurl: `img/puzzle_line.png`,
		style: {
			pointerEvents: 'none',
			width: '100%',
			height: '100%',
			backgroundSize: `100% 100%`,
			left: 0 + 'px',
			top: 0 + 'px',
		},
	})
	unitdom.appendChild(unitdomMask)
	unitdom.style.opacity = '1'
	unitdom.style.zIndex = '20'
	unitdom.style.transformOrigin = `center center`
	const { x: tx, y: ty } = getDomAxis(document.querySelectorAll('.van-image .ui__puzzle-cover')[puzzleNo - 1] as HTMLElement)

	anime({
		targets: unitdom,
		left: [
			{ duration: 0, delay: 2000 },
			{ value: tx - w * 0.275 + 'px', duration: 2000, delay: 0 },
		],
		top: [
			{ duration: 0, delay: 2000 },
			{ value: ty - h * 0.275 + 'px', duration: 2000, delay: 0 },
		],
		filter: [
			{ value: 'blur(5px)', duration: 0, delay: 800, easing: 'easeOutBack' },
			{ value: 'blur(0px)', duration: 300, delay: 0, easing: 'easeOutBack' },
		],
		scale: [
			{ value: '1', duration: 0, delay: 0, easing: 'easeOutSine' },
			{ value: '1.4', duration: 300, delay: 800, easing: 'easeOutSine' },
			{ value: '1', duration: 400, delay: 0, easing: 'easeOutSine' },
			{ value: '0.45', duration: 1000, delay: 750 },
		],
		opacity: [
			{ value: '0', duration: 0, delay: 800, easing: 'linear' },
			{ value: '1', duration: 400, delay: 0, easing: 'linear' },
		],

		loop: 1,
		easing: 'easeInOutExpo',
		complete: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
			//;(anim as AnimeInstance).remove(unitdom)
			//unitdom.remove()
		},
	})
	return unitdom
}
//  创建拼图
export const immCreatePuzzleModel = async (fragments: { sceneId: string; puzzleNo: number }, complete?: (anim: anime.AnimeInstance) => void) => {
	const { sceneId, puzzleNo } = fragments
	const w = 512 / 3.525
	const h = 523 / 3.525
	const { width, height } = getDomAxis(document.querySelector('#app') as HTMLElement)
	console.log(width, height)
	const unitdom = createImgModel({
		parentDom: document.querySelector('.ui__footer-bar .ui__puzzle-container') as Element,
		imgurl: `clips/${sceneId}/puzzle_${puzzleNo}.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
			left: width / 2 - w / 2 + 'px',
			top: height / 2 - h / 2 - 100 + 'px',
		},
	})
	unitdom.className = `puzzle_${puzzleNo}`
	const unitdomMask = createImgModel({
		imgurl: `img/puzzle_line.png`,
		style: {
			pointerEvents: 'none',
			width: '100%',
			height: '100%',
			backgroundSize: `100% 100%`,
			left: 0 + 'px',
			top: 0 + 'px',
		},
	})
	unitdom.appendChild(unitdomMask)
	unitdom.style.opacity = '1'
	unitdom.style.zIndex = '20'
	unitdom.style.transformOrigin = `center center`
	const { x: tx, y: ty } = getDomAxis(document.querySelectorAll('.van-image .ui__puzzle-cover')[puzzleNo - 1] as HTMLElement)

	anime({
		targets: unitdom,
		left: [{ value: tx - w * 0.275 + 'px', duration: 0, delay: 0 }],
		top: [{ value: ty - h * 0.275 + 'px', duration: 0, delay: 0 }],
		scale: [
			//{ value: '1', duration: 0, delay: 0, easing: 'easeOutSine' },
			//{ value: '1.4', duration: 300, delay: 800, easing: 'easeOutSine' },
			//{ value: '1', duration: 400, delay: 0, easing: 'easeOutSine' },
			{ value: '0.45', duration: 0, delay: 0 },
		],
		opacity: [{ value: '1', duration: 400, delay: 0, easing: 'linear' }],

		loop: 1,
		easing: 'easeInOutExpo',
		complete: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
			//;(anim as AnimeInstance).remove(unitdom)
			//unitdom.remove()
		},
	})
	return unitdom
}
//  创建拼图光泽
export const createPuzzleGlossModel = async (fragments: { id: number; puzzleNo: number }, complete?: (anim: anime.AnimeInstance) => void) => {
	const { id, puzzleNo } = fragments
	const w = 256 / 3.75
	const h = 256 / 3.75
	const { x: tx, y: ty } = getDomAxis(document.querySelectorAll('.van-image .ui__puzzle-cover')[puzzleNo - 1] as HTMLElement)
	const unitdom = await unitAni({ parentDom: document.querySelector('.ui__footer-bar') as Element, v2: { x: tx + w * 0.475, y: ty + h * 0.825 }, width: w, height: h, maxheight: 7680 / 3.75, scale: 1, imgurl: 'puzzle_ani_256x256.png' })
	unitdom.style.zIndex = '21'
	unitdom.style.transformOrigin = `0 0`
	anime({
		targets: unitdom,
		backgroundPositionY: `-${unitdom.dataset['backgroundPositionY']}`,
		//direction: 'reverse',
		opacity: [
			{ value: '1', duration: 0, delay: 0, easing: 'linear' },
			{ value: '0.1', duration: 1200, delay: 0, easing: 'linear' },
		],
		//loop: 1,
		duration: 1.2 * 1000,
		easing: `steps(${unitdom.dataset['steps']})`,
		complete: function (anim: anime.AnimeInstance) {
			//if (complete) complete(anim)
			//;(anim as AnimeInstance).remove(unitdom)
			//unitdom.remove()
		},
	})
}
//  小偷模型
type thiefModelType = {
	v2: vector2
	size: size
	imgurl: string
	begin?: (anim: anime.AnimeInstance) => void
	complete?: (anim: anime.AnimeInstance) => void
}
export const createThiefModel = (createData: thiefModelType) => {
	const { v2 = { x: 0, y: 0 }, size = { w: 0, h: 0 }, imgurl = '', begin, complete } = createData
	const dom = createImgModel({
		imgurl: imgurl,
		style: {
			//pointerEvents: 'none',
			width: size.w + 'px',
			height: size.h + 'px',
			backgroundSize: `${size.w}px ${size.h}px`,
			left: v2.x + 'px',
			top: v2.y + 'px',
		},
	})
	anime({
		targets: dom,
		loop: 1,
		duration: 0,
		easing: 'linear',
		begin: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
		},
		complete: function (anim: anime.AnimeInstance) {
			complete && complete(anim)
		},
	})
	return dom
}
//  创建拼图碎片
export const createPuzzlePieceModel = (fragments: { sceneId: string; part: number; puzzleId: string }, v2: vector2, complete?: (anim: anime.AnimeInstance) => void) => {
	const { sceneId, part, puzzleId } = fragments
	const w = 196 / 3
	const h = 196 / 3
	//const part = 2
	const unitdom = createImgModel({
		parentDom: document.querySelector('.ui__footer-bar .ui__puzzle-container') as Element,
		imgurl: `clips/${sceneId}/fragment_${part}.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
			left: v2.x - w / 2 + 'px',
			top: v2.y - h / 2 + 'px',
		},
	})
	unitdom.className = `fragment_${part}`
	unitdom.dataset.puzzleId = puzzleId
	unitdom.style.transform = 'scale(0.2)'
	unitdom.style.opacity = '0.3'
	// 缩小3倍  分三块  再切一半
	let puzzlePart = (part - 1) % 9
	puzzlePart = puzzlePart + 1
	const puzzleNo = parseInt((part - 1) / 9 + '')
	unitdom.style.transformOrigin = `${(w / 3) * Number(((puzzlePart - 1) % 3) + '.5')}px ${(h / 3) * Number(Math.floor((puzzlePart - 1) / 3) + '.5')}px`

	const { x: tx, y: ty } = getDomAxis(document.querySelectorAll('.van-image .ui__puzzle-cover')[puzzleNo] as HTMLElement)

	anime({
		targets: unitdom,
		left: [
			{ value: `+=${(w / 3) * (1 - ((puzzlePart - 1) % 3))}px`, duration: 0, delay: 1000 },
			//{ value: `+=${0}px`, duration: 0, delay: 0 },
			//{ value: `-=${(196 / 3 / 3 / 2) * 6}px`, duration: 0, delay: 0 },
			{ value: tx + 'px', duration: 2000, delay: 0 },
		],
		top: [
			{ value: `+=${(h / 3) * (1 - Math.floor((puzzlePart - 1) / 3)) + -h * 1.5}px`, duration: 0, delay: 1000 },
			{ value: ty + 'px', duration: 2000, delay: 0 },
		],
		scale: [
			{ value: '2', duration: 500, delay: 0, easing: 'easeOutBack' },
			{ value: '1', duration: 1500, delay: 300 },
		],
		opacity: [{ value: '1', duration: 1000, delay: 0, easing: 'easeOutBack' }],
		//rotate: { value: 720, duration: 3000, delay: 0 },
		loop: 1,
		easing: 'easeInOutExpo',
		duration: 6 * 1000,
		complete: function (anim: anime.AnimeInstance) {
			;(anim as AnimeInstance).remove(unitdom)
			//unitdom.remove()
			complete && complete(anim)
		},
	})
	return unitdom
}
//  立即创建拼图碎片
//  创建拼图碎片
export const immCreatePuzzlePieceModel = (fragments: { sceneId: string; part: number; puzzleId: string }, complete?: (anim: anime.AnimeInstance) => void) => {
	const { sceneId, part, puzzleId } = fragments
	const w = 196 / 3
	const h = 196 / 3
	const unitdom = createImgModel({
		parentDom: document.querySelector('.ui__footer-bar .ui__puzzle-container') as Element,
		imgurl: `clips/${sceneId}/fragment_${part}.png`,
		style: {
			pointerEvents: 'none',
			width: w + 'px',
			height: h + 'px',
			backgroundSize: `100% 100%`,
		},
	})
	unitdom.className = `fragment_${part}`
	unitdom.dataset.puzzleId = puzzleId
	unitdom.style.transform = 'scale(1)'
	unitdom.style.opacity = '0.3'
	// 缩小3倍  分三块  再切一半
	let puzzlePart = (part - 1) % 9
	puzzlePart = puzzlePart + 1
	const puzzleNo = parseInt((part - 1) / 9 + '')
	unitdom.style.transformOrigin = `${(w / 3) * Number(((puzzlePart - 1) % 3) + '.5')}px ${(h / 3) * Number(Math.floor((puzzlePart - 1) / 3) + '.5')}px`

	const { x: tx, y: ty } = getDomAxis(document.querySelectorAll('.van-image .ui__puzzle-cover')[puzzleNo] as HTMLElement)

	anime({
		targets: unitdom,
		left: [{ value: tx + 'px', duration: 0, delay: 0 }],
		top: [{ value: ty + 'px', duration: 0, delay: 0 }],
		opacity: [{ value: '1', duration: 0, delay: 0, easing: 'easeOutBack' }],
		loop: 1,
		easing: 'easeInOutExpo',
		duration: 6 * 1000,
		complete: function (anim: anime.AnimeInstance) {
			;(anim as AnimeInstance).remove(unitdom)
			//unitdom.remove()
			complete && complete(anim)
		},
	})
	return unitdom
}
