import { defineStore } from 'pinia'
import { createBiggerModel } from '@/common/sequence_frame'
import { globalStore } from '../store'
import random from 'lodash/random'
//  技能信息
export const Skill = defineStore('Skill', {
	//persist: true,
	state: () => ({
		lockTarget: {
			id: 1,
			name: 'radar',
			cd: 10,
			duration: 2,
			desc: '锁定目标',
			ableimg: '',
			disableimg: '',
			instance: {} as anyObject,
		},
		bigger: {
			id: 1,
			name: 'magnifier',
			cd: 10,
			duration: 2,
			desc: '放大目标',
			ableimg: '',
			disableimg: '',
			instance: {} as anyObject,
		},
		randomGetGoods: {
			id: 1,
			name: 'magnifier',
			cd: 10,
			duration: 2,
			desc: '随机获得物品',
			ableimg: '',
			disableimg: '',
			goodNumRange: [1, 3],
			goodsList: [],
			instance: {} as anyObject,
		},
		synthesis: {
			id: 1,
			name: 'magnifier',
			cd: 0,
			duration: 2,
			desc: '合成物品',
			ableimg: '',
			disableimg: '',
			goodNumRange: [1, 3],
			goodsList: [],
			instance: {} as anyObject,
		},
	}),
	actions: {
		init() {
			console.log('技能初始化中...')
			console.log('技能初始化中...完毕!')
		},
		useRadarEffect({ recovery = false }: { recovery?: boolean } = {}) {
			const { Krpano, Player, Unit } = globalStore
			const { run } = Krpano
			const thiefArr = run
				.get('hotspot')
				.getArray()
				.filter((thief: anyObject) => /hs_/.test(thief.name))
			const thief = thiefArr[random(0, thiefArr.length - 1)]
			console.log('thief.name', thief.name)
			const scale = run.get(`hotspot[${thief.name}].scale`) * 1.5
			//  创建箭头
			setTimeout(() => {
				globalStore.Krpano.createLineAndArrowThief(
					run.get(`hotspot[${thief.name}].ath`),
					run.get(`hotspot[${thief.name}].atv`),
					`lookto(${run.get(`hotspot[${thief.name}].ath`)},${run.get(`hotspot[${thief.name}].atv`)},${thief.scale * 140},tween(linear,1),true,true,
            tween(hotspot[line_t].alpha,0,0.5,default,set(hotspot[line].scale,0););
            tween(hotspot[scene2_t].alpha,0,0.5,default,set(hotspot[scene2].scale,0););
        )`,
				)
			}, 1000)
			run.call(`
      lookto(${run.get(`hotspot[${thief.name}].ath`)},${run.get(`hotspot[${thief.name}].atv`)},${120},default,true,true)`)
			console.log('释放技能')
		},
		usebiggeEffect({ recovery = false }: { recovery?: boolean } = {}) {
			const { Krpano, Player, Unit } = globalStore
			const { run } = Krpano
			const thiefArr = run
				.get('hotspot')
				.getArray()
				.filter((thief: anyObject) => /hs_/.test(thief.name))
			console.log('thiefArrthiefArrthiefArr', thiefArr)
			const timer = setInterval(() => {
				if (Player.bigger.timer === 0) {
					clearInterval(timer)
					thiefArr.forEach((thief: anyObject) => {
						const scale = run.get(`hotspot[${thief.name}].scale`) / 3
						run.call(`tween(hotspot[${thief.name}].scale,${scale},0.5,easeinoutback);`)
						run.call(`addzfilter(hotspot[${thief.name}]);`)
						run.call(`tween(hotspot[${thief.name}].blur, 15, 1);`)
					})
				}
			}, 100)
			thiefArr.forEach((thief: anyObject) => {
				const scale = run.get(`hotspot[${thief.name}].scale`) * 3
				run.call(`tween(hotspot[${thief.name}].scale,${scale},0.5,easeinoutback);`)
				run.call(`addzfilter(hotspot[${thief.name}]);`)
				run.call(`tween(hotspot[${thief.name}].blur, 15, 1);`)
			})
			console.log('释放技能')
		},
		useRandomGetGoodsEffect() {
			console.log('正在随机获取物品...')
		},
		useSynthesisEffect() {
			console.log('正在合成物品...')
		},
	},
})
