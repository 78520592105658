//  持久化引擎目前支持的类型:
//  String, Number, Boolean, Symbol, Bigint, Null, Undefined,
//  Array, Object, Date, Set, Map, RegExp, Function,
//TODO:   WeakSet, WeakMap, Blob, File, Error,  后续补上
//, globalStore as GS
import { createStore, addStoreModule, registerStoreModule as RSM, globalStore as GS } from 'hbooster-core/global/store'

//  导入用户数据
import { Unit } from './store_modules/Unit'
import { Player } from './store_modules/Player'
import { Skill } from './store_modules/Skill'
import { Goods } from './store_modules/Goods'
import { Customs } from './store_modules/Customs'
import { Krpano } from './store_modules/Krpano'
import { Ad } from './store_modules/Ad'

//  添加数据模块 注册模块后即可使用 使用方法例如:globalStore.user.state.age
//  需要添加的数据模块,必须在此处添加,否则无法使用
//  ↓↓↓↓↓===========================================================↓↓↓↓↓
//  定义类型  只有定义类型才能使用属性提示!!
interface GlobalStore {
	Unit: ReturnType<typeof Unit>
	Player: ReturnType<typeof Player>
	Skill: ReturnType<typeof Skill>
	Goods: ReturnType<typeof Goods>
	Customs: ReturnType<typeof Customs>
	Krpano: ReturnType<typeof Krpano>
	Ad: ReturnType<typeof Ad>
}
//  添加数据模块
addStoreModule([
	{ name: 'Unit', install: Unit },
	{ name: 'Player', install: Player },
	{ name: 'Skill', install: Skill },
	{ name: 'Goods', install: Goods },
	{ name: 'Customs', install: Customs },
	{ name: 'Krpano', install: Krpano },
	{ name: 'Ad', install: Ad },
])
//  ↑↑↑↑↑===========================================================↑↑↑↑↑

//  在main.ts中引入,需要在实例化vue后,执行registerStore()
export const registerStoreModule = RSM

//  数据控制器的根属性,所有注册的数据模块都在里面
//  数据中心化管理,避免多次创建,提高性能
export const globalStore: GlobalStore = GS as GlobalStore

//  创建全局数据控制器
export const store = createStore()
