import { APP_ENV } from 'hbooster-core/common/env'

export const proxyUrl = (() => {
	if (APP_ENV.dev) return 'http://localhost:3000'
	else if (APP_ENV.test) return 'http://localhost:3000'
	else return 'http://localhost:3000'
})()

//  接口地址
export const baseUrl = (() => {
	//if (APP_ENV.dev) return 'https://mock.apifox.cn/m1/1449188-0-default'
	if (APP_ENV.dev) return 'http://develop.bigpixel.cn/panogame'
	else if (APP_ENV.test) return 'http://develop.bigpixel.cn/panogame'
	else return 'http://mobile.baowugroup.com/com.baosight.baosecurity/service'
})()
