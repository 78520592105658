import { defineStore } from 'pinia'
import { createBiggerModel, createImgModel, createThiefModel } from '@/common/sequence_frame'
import anime from 'animejs'
import { titleCase } from '@core/global/directive_modules/debounce'
import { globalStore } from '../store'
import { game_v1_rank_v1_thief_found } from '@/common/api'
import { audio } from '@/common/audio'
import random from 'lodash/random'
//  单位信息
export const Unit = defineStore('Unit', {
	//persist: true,
	state: () => ({
		thief: {
			max: 0,
			count: 0,
			id: 0,
			found: [],
			name: 'thief',
			goods: [] as Array<{
				name: string
				own: number
			}>,
			alive: true,
			axis: { x: 0, y: 0, z: 0 } as vector3,
		},
		doubleHit: 0,
		doubleHitTimer: 0,
	}),
	actions: {
		init() {
			console.log('init')
			//if (this.radarData.start) this.useRadarSkill({ recovery: true })
			//if (this.biggerData.start) this.usebiggeSkill({ recovery: true })
		},
		createThief() {
			const { run, addHotspot } = globalStore.Krpano
			const { Player, Customs } = globalStore
			//TODO:  小偷数字不对
			const thiefArr = run.get('hotspot').getArray()
			const self = this as typeof this
			const foundObj = {} as anyObject
			Player.resetCaughtThief(this.thief.found.length)
			this.thief.found.forEach((hid) => {
				//console.log(hid)
				Object.assign(foundObj, { [hid]: true })
			})
			this.thief.max = 0
			thiefArr.forEach((thief: anyObject, index: number) => {
				//console.log(thief.name)
				this.thief.max++
				if (/hs_/.test(thief.name)) {
					thief.onclick = function (e: anyObject) {
						console.log('e', run.get('mouse.clickx'))
						console.log('thief', thief)
						self.clickThief({ unitName: thief.name, unitId: thief.hid })
					}
				}
			})
			thiefArr.forEach((thief: anyObject, index: number) => {
				if (foundObj[thief.hid]) foundObj[thief.hid] = thief.name
			})
			Object.keys(foundObj).forEach((hid: string, index: number) => {
				run.call(`removehotspot(${foundObj[hid]})`)
			})
		},
		async clickThief(createData: { unitName: string; unitId: string; v2?: vector2; size?: size; goods?: Array<{ name: string; own: number }> }) {
			const { run, addHotspot } = globalStore.Krpano
			;(audio as anyObject)[`thief_0${random(1, 10)}`].play()
			const { v2 = { x: 0, y: 0 }, size = { w: 100, h: 100 }, goods = [], unitName, unitId } = createData

			globalStore.Player.addCatchThief()
			const scale = run.get(`hotspot[${unitName}].scale`) * 1.5
			run.call(`tween(hotspot[${unitName}].alpha,0,0.5,default);`)
			run.call(`tween(hotspot[${unitName}].scale,${scale},0.5,easeoutexpo,removehotspot(${unitName}););`)
			run.set(`hotspot[${unitName}].onclick`, null)

			const self = this as typeof this
			const { Goods, Customs, Ad } = globalStore
			const { data } = (await game_v1_rank_v1_thief_found({ sceneId: Customs.curlevel, thiefId: unitId }, {})) as anyObject
			console.log('resData', data)
			const x = run.get('mouse.stagex')
			const y = run.get('mouse.stagey')

			//  20秒未找到，系统弹出看激励广告帮助找宝（不浪费雷达）
			//clearTimeout(Ad.timer)
			//Ad.timer = setTimeout(() => {
			//	Ad.adTips()
			//}, 20 * 1000)

			//  10秒钟内找到找到三个弹出警察鼓励，奖励100G币
			self.doubleHit = self.doubleHit + 1
			if (self.doubleHit === 3) {
				console.log('奖励100金币')
				self.doubleHit = 0
				clearTimeout(self.doubleHitTimer)
				self.doubleHitTimer = 0
			}
			self.doubleHitTimer = setTimeout(() => {
				self.doubleHit = 0
				self.doubleHitTimer = 0
			}, 3.3 * 1000)

			//  获取物品
			setTimeout(() => {
				Promise.resolve()
					.then(() => {
						if (data.coin) {
							audio.gold_coin.play()
							Goods.createCoin({ x: x, y: y }, data)
							return new Promise((resolve) => setTimeout(() => resolve({}), 3000))
						} else {
							return Promise.resolve()
						}
					})
					.then(() => {
						if (data.radar) {
							Goods.createRadar({ x: x, y: y })
							return new Promise((resolve) => setTimeout(() => resolve({}), 3000))
						} else {
							return Promise.resolve()
						}
					})
					.then(() => {
						if (data.big) {
							Goods.createMagnifier({ x: x, y: y })
							return new Promise((resolve) => setTimeout(() => resolve({}), 3000))
						} else {
							return Promise.resolve()
						}
					})
					.then(() => {
						//data.fragment = true
						//TODO:  puzzle 要删掉这是因为mock数据与测试数据字段不一致的原因造成的
						if (data.fragment || data.puzzle) {
							//data.fragmentInfo = {
							//	id: '46923777',
							//	part: 18,
							//	puzzleId: '46333953',
							//}
							Goods.createPuzzle({ x: x, y: y }, data)
							return new Promise((resolve) => setTimeout(() => resolve({}), 3000))
						} else {
							return Promise.resolve()
						}
					})
			}, 200)
		},
	},
})
